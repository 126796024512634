
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as _91item_93heWVfuxmzDMeta } from "/vercel/path0/pages/accommodations/[item].vue?macro=true";
import { default as indexvLnRZCzLa9Meta } from "/vercel/path0/pages/accommodations/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meetingformyqklQBmRwxMeta } from "/vercel/path0/pages/meetingform.vue?macro=true";
import { default as _91item_93Z6s9hH0QMEMeta } from "/vercel/path0/pages/meetingpackages/[item].vue?macro=true";
import { default as indexdbYcUSRZncMeta } from "/vercel/path0/pages/meetingpackages/index.vue?macro=true";
import { default as meetingroomsePjiPPEGcdMeta } from "/vercel/path0/pages/meetingrooms.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as weddingskZrmSdZE2fMeta } from "/vercel/path0/pages/weddings.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "accommodations-item___nl",
    path: "/accommodaties/:item()",
    component: () => import("/vercel/path0/pages/accommodations/[item].vue")
  },
  {
    name: "accommodations-item___en",
    path: "/en/accommodations/:item()",
    component: () => import("/vercel/path0/pages/accommodations/[item].vue")
  },
  {
    name: "accommodations-item___de",
    path: "/de/unterkunfte/:item()",
    component: () => import("/vercel/path0/pages/accommodations/[item].vue")
  },
  {
    name: "accommodations___nl",
    path: "/accommodaties",
    component: () => import("/vercel/path0/pages/accommodations/index.vue")
  },
  {
    name: "accommodations___en",
    path: "/en/accommodations",
    component: () => import("/vercel/path0/pages/accommodations/index.vue")
  },
  {
    name: "accommodations___de",
    path: "/de/unterkunfte",
    component: () => import("/vercel/path0/pages/accommodations/index.vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___nl",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___nl",
    path: "/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___en",
    path: "/en/highlights",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___de",
    path: "/de/sehenswurdigkeiten",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "meetingform___nl",
    path: "/vergaderformulier",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___en",
    path: "/en/meeting-form",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___de",
    path: "/de/besprechungsformular",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingpackages-item___nl",
    path: "/vergader-arrangementen/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___en",
    path: "/en/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___de",
    path: "/de/tagungspakete/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages___nl",
    path: "/vergader-arrangementen",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___en",
    path: "/en/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___de",
    path: "/de/tagungspakete",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingrooms___nl",
    path: "/vergaderzalen",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "meetingrooms___en",
    path: "/en/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "meetingrooms___de",
    path: "/de/tagungsraume",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "news-item___nl",
    path: "/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___en",
    path: "/en/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___de",
    path: "/de/nachrichten/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news___nl",
    path: "/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "packages-item___nl",
    path: "/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___nl",
    path: "/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "privacy___nl",
    path: "/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "reviews___nl",
    path: "/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "weddings___nl",
    path: "/trouwen",
    component: () => import("/vercel/path0/pages/weddings.vue")
  },
  {
    name: "weddings___en",
    path: "/en/weddings",
    component: () => import("/vercel/path0/pages/weddings.vue")
  },
  {
    name: "weddings___de",
    path: "/de/heiraten%20",
    component: () => import("/vercel/path0/pages/weddings.vue")
  }
]